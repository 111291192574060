import phoneNumbers from '@bold/common/constants/phone-numbers'
import { graphql } from 'gatsby'
import React from 'react'
import BlogMarkdown from 'blog/components/BlogMarkdown'
import Copy from 'blog/components/Copy'
import Container from 'components/Container'
import Head from 'components/Head'
import Heading from 'components/Heading'
import Image from 'components/Image'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import emails from 'constants/emails'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'

type Props = {
  data: unknown
}

export default function Press({ data }: Props) {
  useEventPageVisited('Press post', { name: data?.mdx?.frontmatter?.title })

  if (!data) return null

  const { body, frontmatter } = data.mdx
  const { image, title } = frontmatter

  const bodyComponent = body ? <BlogMarkdown>{body}</BlogMarkdown> : null

  return (
    <Layout className="Press">
      <Head
        title={frontmatter.metadata?.title || title}
        titleAppend="Press pass"
        description={frontmatter.metadata?.description}
        image={image ? image.publicURL : ''}
      />
      <View>
        <Container size="xlarge">
          <Row>
            <Text element="h1" size="xxxlarge" weight="semibold">
              {title}
            </Text>
          </Row>
          {image && (
            <Row size="large">
              <Image src={image} rounded />
            </Row>
          )}
          <Copy>
            {bodyComponent}
            <Text element="h3">About Bold</Text>
            <Text element="p">
              Bold is a digital health and wellness company focused on disease prevention and
              healthy aging. Utilizing personalized and research-backed on-demand exercise programs
              we help our members get stronger and healthier so that they can chase the life they
              want at any age. At a time in which the cost of healthcare, particularly for older
              adults, continues to soar, Bold serves to reduce cost and increase access for older
              adults and health insurers alike — in real-time use as well as in serving as a
              preventative measure. In February 2021,{' '}
              <Link to={paths.PRESS_BOLD_ANNOUNCES_7_MILLION_IN_SEED_FUNDING}>
                Bold raised $7 million in seed funding
              </Link>
              .
            </Text>
            <Heading level={3}>Media contact</Heading>
            Mary Barrett
            <br />
            <Link to={emails.PRESS} />
            <br />
            <Link to={phoneNumbers.PRESS} />
          </Copy>
        </Container>
      </View>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PressArticleQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
        metadata {
          title
          description
        }
        title
      }
    }
  }
`
